
import { defineComponent, ref, toRefs } from 'vue';
import Multiselect from '@vueform/multiselect';
import CategoryResource from '@/resources/CategoryResource';

export default defineComponent({
    components: { Multiselect },
    props: ['filters'],

    setup(props) {
        const { filters } = toRefs(props);
        const categoryOptions = ref([]);

        CategoryResource.query().then((response) => {
            categoryOptions.value = response.data.data;
        });

        return {
            categoryOptions,
            form: filters,
        }
    }
});
