import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field is-horizontal" }
const _hoisted_2 = { class: "field-body" }
const _hoisted_3 = { class: "field" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "control" }
const _hoisted_6 = { class: "field" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = { class: "control" }
const _hoisted_9 = { class: "field" }
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { class: "control mb-3" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "control" }
const _hoisted_14 = { class: "field" }
const _hoisted_15 = { class: "label" }
const _hoisted_16 = { class: "control mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('product.SKU')), 1),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "input",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.sku) = $event))
          }, null, 512), [
            [_vModelText, _ctx.form.sku]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('product.NAME')), 1),
        _createElementVNode("div", _hoisted_8, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "input",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.name) = $event))
          }, null, 512), [
            [_vModelText, _ctx.form.name]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('inventory.QTY_FROM')), 1),
        _createElementVNode("div", _hoisted_11, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "input",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.inventory.qty_from) = $event))
          }, null, 512), [
            [_vModelText, _ctx.form.inventory.qty_from]
          ])
        ]),
        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('inventory.QTY_TO')), 1),
        _createElementVNode("div", _hoisted_13, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "input",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.inventory.qty_to) = $event))
          }, null, 512), [
            [_vModelText, _ctx.form.inventory.qty_to]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('category.CATEGORIES')), 1),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_Multiselect, {
            class: "",
            modelValue: _ctx.form.category_ids,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.category_ids) = $event)),
            mode: "tags",
            "value-prop": "id",
            label: "name",
            searchable: true,
            "track-by": "name",
            options: _ctx.categoryOptions
          }, null, 8, ["modelValue", "options"])
        ])
      ])
    ])
  ]))
}