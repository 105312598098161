
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import ProductResource from "@/resources/ProductResource";
import Categories from "@/components/Product/Categories.vue";
import Pagination from "@/components/Pagination/Pagination.vue";
import TableToolbar from "@/components/TableToolbar.vue";
import Thumbnail from "@/components/Product/Category/Thumbnail.vue";
import TableFilters from "@/components/TableFilters.vue";
import Filters from "@/components/Product/Filters.vue";
import useList from "@/composables/use-list";
import ProductReservationResource from "@/resources/ProductReservationResource";

export default defineComponent({
  components: {
    Filters,
    TableFilters,
    Thumbnail,
    Categories,
    TableToolbar,
    Pagination,
  },

  setup() {
    const filters = reactive({
      sku: undefined,
      name: undefined,
      inventory: {
        qty_from: undefined,
        qty_to: undefined,
      },
      category_ids: undefined,
    });
    const { state, fetch, pagination, removeItem } = useList(ProductResource);
    const list = toRefs(state);

    function submitFilters() {
      fetch({ page: 1, filters });
    }

    function clearFilters() {
      Object.assign(filters, {
        sku: undefined,
        name: undefined,
        inventory: {
          qty_from: undefined,
          qty_to: undefined,
        },
        category_ids: undefined,
      });

      fetch();
    }

    function exportToExcel() {
      ProductResource.exportToExcel({ filters: filters });
    }

    onMounted(() => {
      fetch({ expand: "categories,inventory,media" });
    });

    return {
      filters,
      clearFilters,
      submitFilters,
      items: list.items,
      pagination,
      removeItem,
      exportToExcel,
    };
  },
});
